<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'checkout-summary-status',
  components: {
    BaseIcon,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    status() {
      const { status = '' } = this.order

      return status.toLowerCase()
    },
    financialStatus() {
      const { financialStatus = '' } = this.order

      return financialStatus.toLowerCase()
    },
    isCompleted() {
      return (
        this.financialStatus === 'paid' &&
        ['processing', 'completed'].includes(this.status)
      )
    },
    isProcessing() {
      return !this.isCompleted && this.status === 'processing'
    },
    isFailed() {
      return !this.isProcessing && !this.isCompleted
    },
    iconColor() {
      if (this.isCompleted) {
        return 'bg-fg-success'
      }
      if (this.isProcessing) {
        return 'bg-fg-warning'
      }
      return 'bg-fg-error'
    },
    icon() {
      return this.isFailed ? 'plain/close' : 'plain/check-rounded'
    },
    label() {
      if (this.isProcessing) {
        return 'Order processing'
      } else if (this.isCompleted) {
        return 'Order successful'
      } else {
        return 'Order cancelled'
      }
    },
  },
}
</script>

<template>
  <div class="flex items-center justify-center px-5 pb-5 md:px-10">
    <div class="flex flex-col items-center">
      <div
        class="flex h-24 w-24 items-center justify-center rounded-full text-white"
        :class="iconColor"
      >
        <base-icon :svg="icon" :size="56" />
      </div>
      <h2 class="mt-6 text-2xl font-bold">
        {{ label }}
      </h2>
    </div>
  </div>
</template>
